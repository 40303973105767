<template>
  <div class="container">
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <Header title="Events About to Start"/>
      <v-data-table v-if="events"
        :headers="headers"
        :items="events"
        :items-per-page="25"
        >
        <template v-slot:item.name="{ item }">
          <router-link :to="{name: 'eventmanagerView', params: {id: item.id}}">{{item.name}}</router-link>
          
        </template>
        <template v-slot:item.license="{ item }">
          <v-chip v-if="item.id.startsWith('trial-')" outlined small>TRIAL</v-chip>
          <span v-else>
            {{ item.license }}
            <span v-if="item.seats" class="text-muted ml-2">({{ item.seats }})</span>
          </span>
        </template>
        <template v-slot:item.email="{ item }">
          {{ item.email }}
          <v-icon v-if="item.remarks" x-small :title="item.remarks">fa fa-comment</v-icon>
        </template>
        <template v-slot:item.created_on="{ item }">
          {{ item.created_on | localDate('L LT') }}
        </template>
        <template v-slot:item.from="{ item }">
          {{ item.from | localDate('L') }}
        </template>
        <template v-slot:item.till="{ item }">
          {{ item.till | localDate('L') }}
        </template>
        <template v-slot:item.flags="{ item }">
          <v-icon v-if="item.has_slack" class="mr-2" title="Slack Integration Enabled">fab fa-slack</v-icon>
          <v-icon v-if="item.has_teams" class="mr-2" title="Microsoft Teams Integration Enabled">fa fa-users</v-icon>
        </template>

      </v-data-table>

    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Recent Events",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      events: null,
      headers: [
        //{ text: 'Id', value: 'id', sortable: true},
        //{ text: 'Created On', value: 'created_on', sortable: true},
        { text: 'Name', value: 'name', sortable: true},
        { text: 'Email (organizer)', value: 'email', sortable: true},
        //{ text: 'Is Published', value: 'published', sortable: true},
        { text: 'Active From', value: 'from', sortable: true},
        { text: 'Active Till', value: 'till', sortable: true},
        { text: 'License', value: 'license', sortable: true},
        { text: '# participants', value: 'participant_count', sortable: true},
        { text: '# regs', value: 'reg_count', sortable: true},
        { text: 'Options', value: 'flags', sortable: false},
      ],
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        this.events = (await eventManagerService.getUpcomingEvents()).data.data;
      }
    },
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Upcoming Events', disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

