import { EventBus } from '@/plugins/eventbus.js';
import eventManagerService from "@/services/eventManagerService";
import tenants from '@/data/tenants.config'
import helpers from '@/helpers.js'
const tenant = tenants.current();

const EventStatus = Object.freeze({ DRAFT: 1, ONBOARDING: 2, ACTIVE: 3, COMPLETED: 4 });

class EventUtil {

    EventStatus = EventStatus;//Object.freeze({ DRAFT: 1, ONBOARDING: 2, ACTIVE: 3, COMPLETED: 4 });

    constructor(context, event, race) {
      this.context = context;
      this.service = eventManagerService;
      this.tenant = tenant;  
      this.event = event;
      this.race = race || null;
      this.newLayout = window.$cookies.isKey('newlayout') ? window.$cookies.get('newlayout') === 'true' : true; // default on for everyone now
    }

    isDevelopment() {
      return process.env.NODE_ENV == 'development';
    }

    isPublished() {
      return this.event && this.event.published;
    }

    showAdvancedEventOptions() { return !this.hideAdvancedEventOptions(); }
    hideAdvancedEventOptions() {
      // Hide advanced event options when linked to a template
      return this.hasMeta('cfg.template.id') && !(this.hasPaidSupportPlan() || this.hasMeta('cfg.template.unlocked_at'));
    }

    showAdvancedLeaderboardOptions() { return !this.hideAdvancedLeaderboardOptions(); }
    hideAdvancedLeaderboardOptions() {
      // Hide advanced leaderboard options when linked to a template
      return this.hasMeta('cfg.template.id') && !(this.hasPaidSupportPlan() || this.hasMeta('cfg.template.unlocked_at'));
    }

    isFreePlan() {
      if (!this.event) return false;
        return this.event.license === 'FREE';
    }

    isBasicPlan() {
      if (!this.event) return false;
        return this.event.license === 'FREE' || this.event.license === 'BASIC';
    }

    isProPlan() {
      if (!this.event) return false;
      return tenant.id !== 'cofi' || this.event.license === 'PRO' || this.isEnterprisePlan();
    }
    
    isEnterprisePlan() {
      if (!this.event) return false;
      return this.event.license === 'ENTERPRISE';
    }

    hasPaidSupportPlan() {
      if (!!this.event && tenant.id === 'wmm') return true;
      if (!this.event || tenant.id !== 'cofi') return false;
      var plan = this.getMeta('invoiced.support_plan');
      return plan != null && plan != 'self-service';
    }

    getSupportPlan() {
      return this.getMetaStringValue('invoiced.support_plan') || 'self-service';
    }

    hasApp() {
      if (!this.event) return false;
      return tenant.id === 'cofi' || this.event.app_bundle_id;
    }
    hasDeeplinking() {
      if (!this.event) return false;
      return tenant.id === 'cofi' || (this.event.meta && this.event.meta.some(x => x.key === 'firebase.link_domain' || x.key === 'branch.link_domain'));
    }

    hasMeta(key) {
      if (!this.event) return false;
      return this.event.meta && this.event.meta.some(x => x.key === key);
    }
    getMeta(key) {
      return this.event && this.event.meta && this.event.meta.find(x => x.key === key);
    }
    getMetaStringValue(key) {
      var meta = this.getMeta(key);
      return meta && meta.val_str;
    }
    getMetaIntValue(key) {
      var meta = this.getMeta(key);
      return meta && meta.val_int;
    }

    supportsGeoFence() {
      if (!this.event || !this.race) return false;
      return this.race.type !== 'EXTERNAL';
    }

    supportsCourseMap() {
      if (!this.event || !this.race) return false;
      return this.race.type !== 'EXTERNAL' && (this.race.scoring == 'DISTANCE'||this.race.scoring == 'ELEVATION'||this.race.scoring == 'TRACK'||this.race.scoring == 'CUSTOM'||this.race.scoring == 'STEPS'||this.race.scoring == 'TIME');
    }

    supportsBadges() {
      if (!this.event || !this.race) return false;
      return this.race.type !== 'EXTERNAL';
    }

    supportsSplits() {
      if (!this.event || !this.race) return false;
      return this.race.type === 'EXTERNAL';
    }

    supportsResultsUpload() {
      if (!this.event || !this.race) return false;
      return this.race.type === 'EXTERNAL' || this.race.type === 'HYBRID';
    }

    promoteResultsUpload() {
      if (!this.event) return false;
      return this.event.type === 'RESULTS' || this.event.type === 'HYBRID';
    }

    isCustomActivityTypeChallenge() {
      return this.race && this.race.activity_types && this.race.activity_types.length == 1 &&  this.race.activity_types[0] == "CUSTOM";
    }
    
    registrationUrl() {
      return 'https://' + location.host + this.context.$router.resolve({name: 'eventReg', params: {id: this.event.id}}).href;
    }
    gettingStartedUrl() {
      return 'https://' + location.host + this.context.$router.resolve({name: 'eventGettingStarted', params: {id: this.event.id}}).href;
    }
    gettingStartedChinaUrl(code) {
      return 'https://' + location.host + this.context.$router.resolve({name: 'eventGettingStartedChina', params: {id: this.event.id}, query: {code: code}}).href;
    }
    
    currentStatus() {
      if (!this.event) {
        return null;
      }
      if (!this.event.published) {
        return EventStatus.DRAFT;
      }
      else if (this.context.$helpers.isFutureEvent(this.event)) {
        return EventStatus.ONBOARDING;
      }
      else if (this.context.$helpers.isActiveEvent(this.event)) {
        return EventStatus.ACTIVE;
      }
      return EventStatus.COMPLETED;
    }
    
    hasFullAccess() {
      return this.hasRole('FULL_ACCESS');
    }
    hasConfigEditRole() {
      return this.hasRole(['FULL_ACCESS', 'CONFIG_RW']);
    }
    hasRole(role) {
      if (Array.isArray(role)) {
        if (!this.event || !this.event.my_roles) {
          return false;
        }
        return this.event.my_roles.some(x => role.includes(x));
      }
      return this.event && this.event.my_roles && this.event.my_roles.includes(role);
    }

    async refresh() {
      if (!this.event) return false;

      const eventId = this.event && this.event.id;
      const raceId = this.race && this.race.id;
      var response = await eventManagerService.get(eventId);
      this.event = response.data;
      this.race = this.event && this.event.races && raceId ? this.event.races.find(x => x.id == raceId) : null;

      await EventBus.$emit('eventmanager-refresh', this);
      return true;
    }

    async requestSave() {
      console.log('save requested');
      EventBus.$emit('eventmanager-request-save');
    }
    async save() {
      if (!this.event) return false;

      try {
        //console.log('saving...', this.event, 'etag', this.event._etag);
        var response = await eventManagerService.put(this.event);
        //console.log('save response', response, 'etag', response && response.data && response.data._etag);
        this.context.$helpers.toastResponse(this.context, {status: 'OK'}, 'Event saved successfully.');

        // reload data
        const raceId = this.race && this.race.id;
        this.event = response.data;
        this.race = this.event && this.event.races && raceId ? this.event.races.find(x => x.id == raceId) : null;

        await EventBus.$emit('eventmanager-refresh', this);

        return true;
      }
      catch (ex) {
        console.log('Error in saving', ex, ex.response);
        if (ex.response && ex.response.data) {
          this.error = ex.response.data.msg;
          this.context.$helpers.toastResponse(this.context, ex.response.data, '');
        }
        // get a fresh copy to prevent unsaved data from showing
        this.refresh();
      }
      return false;
    }

    getValueForDisplay(value) {
      return this.context.$helpers.getValueForDisplay(this.context.$options, this.race, { score_value: value}, this.event);
    }

    goalDistanceUnitType() {
      if (this.race != null && (this.race.scoring == "CALORIES" || this.race.scoring == "STEPS" || this.race.scoring == "TILES"  || this.race.scoring == "TIME" || this.race.scoring == "STAIRS" || this.race.scoring == "BADGES" || this.race.scoring == "CUSTOM")){
        return helpers.UnitType.NUMBER;
      }
      else if (this.race != null && (this.race.scoring == "ELEVATION" || this.race.scoring == "ELEVATION_LOSS")){
        return helpers.UnitType.ELEVATION;
      }
      return helpers.UnitType.DISTANCE;
    }
    goalDistanceMultiplier() {
      if (this.race.scoring == "TIME") {
        return 3600;
      }
      return 1;
    }
    goalLabel() {
      if (!this.race || this.race.scoring == null || this.race.scoring == "RESULT" || this.race.scoring == "TRACK") {
        return `Goal distance (in ${this.unitTypeDisplay()})`;
      }
      else if (this.race.scoring == "DISTANCE"){
        return `Distance goal (in ${this.unitTypeDisplay()})`;
      }
      else if (this.race.scoring == "STEPS"){
        return `Step goal`;
      }
      else if (this.race.scoring == "ELEVATION" || this.race.scoring == "ELEVATION_LOSS"){
        return `Elevation goal (in ${this.unitTypeDisplay()})`;
      }
      else if (this.race.scoring == "CALORIES"){
        return `Total calorie goal (in kcal)`;
      }
      else if (this.race.scoring == "TIME"){
        return `Time goal (in hours)`;
      }
      else if (this.race.scoring == "BADGES"){
        return `Total badges to earn`;
      }
      else if (this.race.scoring == "STAIRS"){
        return `Total number of stairs goal`;
      }
      else if (this.race.scoring == "CUSTOM"){
        return `Total ${this.race.custom || 'score'} goal`;
      }
      return null;
    }
    unitTypeDisplay() {
      if (this.race != null && (this.race.scoring == "ELEVATION" || this.race.scoring == "ELEVATION_LOSS")){
        return this.event == null || this.event.unit == 'METRIC' ? 'm' : 'ft';
      }
      return this.event == null || this.event.unit == 'METRIC' ? 'km' : 'mi';
    }
  }

export default EventUtil;